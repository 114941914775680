<!-- src/views/PlaceholderPage.vue -->
<template>
  <div class="container mx-auto px-4 py-8 text-center">
    <h1 class="text-3xl font-bold mb-4">{{ $route.name }} Page</h1>
    <p>This page is under construction.</p>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderPage',
};
</script>